import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchSelect, Flex, Typo, Modal } from '../../../../ui';

const BuildSuggestedCourse = ({
  allCourses,
  info,
  open, 
  onClose, 
  addToPreview
}) => {
  const { t } = useTranslation();

  const [suggestedCourse, setSuggestedCourse] = useState('');

  useEffect(() => {
    setSuggestedCourse(info)
  }, [info])

  const onAccept = useCallback(() => {
    addToPreview(suggestedCourse)
  }, [suggestedCourse, addToPreview]);

  return (
    <Modal
    open={open} 
    showActions={true}
    onClose={onClose}
    onCancel={onClose}
    onAccept={onAccept}
    acceptLabel='Add to preview'
  >
    <Flex direction='column' width='100%' align='flex-start'>
      <Typo.ContentSmallHeader>{t('webshop.build.suggestedCourseTitle')}</Typo.ContentSmallHeader>
      <SearchSelect
        id="promote-course"
        options={allCourses}
        selectedOptions={suggestedCourse}
        label={t('globals.courses')}
        setValue={setSuggestedCourse}
      />
    </Flex>
    </Modal>
  );
};

export default BuildSuggestedCourse;
