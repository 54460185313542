import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';

import { CONTENT_FULL } from '../../../utils/media';
import { Typo, SubPage, Flex, Button, theme } from '../../../ui';

import { saveLayout, selectLayout } from '../homeSlice';
import { selectStoreName } from '../globalSlice';
import { selectCourseById, selectCourses, selectHighlightedCoursesInfo } from '../coursesSlice';

import CoverMediaLayout from './components/CoverMediaLayout';
import BuildSuggestedCourse from './components/BuildSuggestedCourse';
import BuildSpotlightCourses from './components/BuildSpotlightCourses';

import BuildInfoIcons from '../components/InfoIconSection/BuildInfoIcons';
import BuildInfoPoints from '../components/InfoPointsSection/BuildInfoPoints';
import { List, ListItem, ListItemButton } from '@mui/material';
import CheckboxButton from '../../../ui/CheckboxButton';
import styled from 'styled-components';
import SettingsButton from './SettingsButton';
import ViewShopFrontLayout from '../ShopViewer/ViewShopFrontLayout';
import EditCtaBanner from './components/EditCtaBanner';
import EditIntroduction from './components/EditIntroduction';

const PreviewContainer = styled.div`
  width:80%;
  border: 2px solid gray;
  height: calc(100vh - ${theme.headerSize} - 140px);
  overflow-y: scroll;
`

export const cleanHighlightedCourses = (data) => {
  return data.map((item) => {
    return item.id
  })
}

const EditShopFrontLayout = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()

  const layoutStructure = useSelector(selectLayout);
  const courses = useSelector(selectCourses);
  const highlightedCoursesInfo = useSelector(selectHighlightedCoursesInfo);
  const storeName = useSelector(selectStoreName);
  const suggestedCourse = useSelector(state => selectCourseById(state, layoutStructure.suggestedCourse))

  const [layoutInfo, setLayoutInfo] = useState();

  const [openCoverMedia, setOpenCoverMedia] = useState(false);
  const [openInfoIcons, setOpenInfoIcons] = useState(false);
  const [openInfoPoints, setOpenInfoPoints] = useState(false);
  const [openCtaBanner, setOpenCtaBanner] = useState(false);
  const [openIntroduction, setOpenIntroduction] = useState(false);
  const [openHighlightedCourses, setOpenHighlightedCourses] = useState(false);
  const [openSuggestedCourse, setOpenSuggestedCourse] = useState(false);
  const [needToBeSaved, setNeedToBeSaved] = useState(false);


  useEffect(() => {
    if (layoutStructure) {

      const tempLayout = cloneDeep(layoutStructure)
      tempLayout['activeModules'] = {
        introduction: !!tempLayout?.introduction && !!Object.keys(tempLayout?.introduction)?.length,
        infoIcons: !!tempLayout?.infoIcons && !!Object.keys(tempLayout?.infoIcons)?.length,
        infoPoints: !!tempLayout?.infoPoints && !!Object.keys(tempLayout?.infoPoints)?.length,
        coverMedia: !!tempLayout?.coverMedia?.image ||  !!tempLayout?.coverMedia?.video,
        highlightedCourses: cloneDeep(highlightedCoursesInfo),
        suggestedCourse: cloneDeep(suggestedCourse),
        ctaBanner: true,
      }
      
      setLayoutInfo(tempLayout)
    }
  }, [layoutStructure, highlightedCoursesInfo, suggestedCourse]);

  useEffect(() => {
    let needSave = false
    if (JSON.stringify(layoutInfo?.introduction) !== JSON.stringify(layoutStructure.introduction) ||
      JSON.stringify(layoutInfo?.infoIcons) !== JSON.stringify(layoutStructure.infoIcons) ||
      JSON.stringify(layoutInfo?.suggestedCourse) !== JSON.stringify(suggestedCourse) ||
      JSON.stringify(layoutInfo?.infoPoints) !== JSON.stringify(layoutStructure.infoPoints) ||
      JSON.stringify(layoutInfo?.highlightedCourses) !== JSON.stringify(highlightedCoursesInfo) ||
      JSON.stringify(layoutInfo?.ctaBanner) !== JSON.stringify(layoutStructure.ctaBanner) ||
      JSON.stringify(layoutInfo?.coverMedia) !== JSON.stringify(layoutStructure.coverMedia)
    ) needSave = true

    setNeedToBeSaved(needSave)
  }, [layoutStructure, layoutInfo, highlightedCoursesInfo,  suggestedCourse,])

  const removeMockedIds = useCallback((data) => {
    return data.map(el => {
      if (el.hasOwnProperty('id')) {
        delete el.id;
      }
      return el;
    })
  }, [])

  const saveStructure = useCallback(() => {
    let newLayout = {
      coverMedia: layoutInfo.coverMedia,
      introduction: layoutInfo.introduction,
      infoPoints: removeMockedIds(layoutInfo.infoPoints),
      infoIcons: removeMockedIds(layoutInfo.infoIcons),
      suggestedCourse: layoutInfo.suggestedCourse?.id,
      highlightedCourses: cleanHighlightedCourses(layoutInfo.highlightedCourses),
      ctaBanner: layoutInfo.ctaBanner,
    }
    dispatch(saveLayout({ storeName, layoutData: newLayout }));
  }, [dispatch, storeName, removeMockedIds, layoutInfo]);

  const checkIfHasData = (target, info) => {
    if(target === 'coverMedia') return !!info?.image ||  !!info?.video
    else return !!info && (!!info.length || !!Object.keys(info)?.length)
  }

  const updatePreviewShowElement = (e, target, openModuleFnc) => {
    const checked = e.target.checked
    checked && !!openModuleFnc && !checkIfHasData(target, layoutInfo[target]) && openModuleFnc(true)
    setLayoutInfo({...layoutInfo, activeModules: {...layoutInfo.activeModules, [target]: checked}})
  }

  const addElementToPreview = (info, target, openModuleFnc) => { 
    const hasData = checkIfHasData(target, info)
    setLayoutInfo({...layoutInfo, [target]: info, activeModules: {...layoutInfo.activeModules, [target]: hasData}})
    openModuleFnc && openModuleFnc(false)
  }

  const addToPreviewCtaBanner = (info) => {
    setLayoutInfo({...layoutInfo, ctaBanner: info})
    setOpenCtaBanner(false)
  }
  
  const MenuItem = ({labelText, checked, onChange, openModule, disabled}) => 
    <ListItem component="div" disablePadding>
      <ListItemButton disableGutters >
        { !disabled ? <CheckboxButton labelText={labelText}
          checked={checked} 
          handleChange={onChange} 
        /> : <Typo.Text>{labelText}</Typo.Text>}
      </ListItemButton>
      {!!openModule && <SettingsButton onClick={openModule}/>}
    </ListItem>

  return (
    <SubPage align='flex-start' gap='50' size={CONTENT_FULL} >
      <Flex width='100%' align='flex-start' gap='20' justify='flex-start' >
        {/* SETTINGS MENU */}
        <div>
          <List dense subheader={<Typo.TextTitle>Activate modules</Typo.TextTitle>}>
            <MenuItem 
              labelText='Cover Media' 
              checked={layoutInfo?.activeModules?.coverMedia} 
              onChange={(e) => updatePreviewShowElement(e, 'coverMedia', setOpenCoverMedia)} 
              openModule={() => setOpenCoverMedia(true)}
            />

            <MenuItem 
              labelText='CTA Banner' 
              checked={true}//{course?.activeModules?.ctaBanner} 
              disabled={true}
              onChange={(e) => updatePreviewShowElement(e, 'ctaBanner', setOpenCtaBanner)}
              openModule={() => setOpenCtaBanner(true)}
            /> 

            <MenuItem 
              labelText='Information points with icons' 
              checked={layoutInfo?.activeModules?.infoIcons}
              onChange={(e) => updatePreviewShowElement(e, 'infoIcons', setOpenInfoIcons)} 
              openModule={() => setOpenInfoIcons(true)}
            />

            <MenuItem 
              labelText='Introduction of the store' 
              checked={layoutInfo?.activeModules?.introduction} 
              onChange={(e) => updatePreviewShowElement(e, 'introduction', setOpenIntroduction)} 
              openModule={() => setOpenIntroduction(true)}
            /> 
            
            <MenuItem 
              labelText='Highlight courses' 
              checked={layoutInfo?.activeModules?.highlightCourses} 
              onChange={(e) => updatePreviewShowElement(e, 'highlightedCourses', setOpenHighlightedCourses)} 
              openModule={() => setOpenHighlightedCourses(true)}
            /> 

            <MenuItem 
              labelText='Description points' 
              checked={layoutInfo?.activeModules?.infoPoints} 
              onChange={(e) => updatePreviewShowElement(e, 'infoPoints', setOpenInfoPoints)} 
              openModule={() => setOpenInfoPoints(true)}
            /> 
            <MenuItem 
              labelText='Promote a course' 
              checked={layoutInfo?.activeModules?.suggestedCourse} 
              onChange={(e) => updatePreviewShowElement(e, 'suggestedCourse', setOpenSuggestedCourse)} 
              openModule={() => setOpenSuggestedCourse(true)}
            /> 
            
          </List>
          <Button icon='Save' onClick={saveStructure} disabled={!needToBeSaved} >{t('globals.save')}</Button>

        </div>

        {/* PREVIEW */}
        <PreviewContainer>
          <ViewShopFrontLayout
            layoutStructure={layoutInfo}            
            {...layoutInfo}
          /> 
        </PreviewContainer>

      </Flex>

      <CoverMediaLayout
        open={openCoverMedia} 
        info={layoutInfo?.coverMedia}
        addToPreview={(info) => addElementToPreview(info, 'coverMedia', setOpenCoverMedia)}
        onClose={() => {
          addElementToPreview(layoutInfo.coverMedia, 'coverMedia')
          setOpenCoverMedia(false)
        }}
      />   

      <EditCtaBanner
        open={openCtaBanner} 
        info={layoutInfo?.ctaBanner}
        onClose={() => {
          addElementToPreview(layoutInfo.ctaBanner, 'ctaBanner')
          setOpenCtaBanner(false)
        }}
        addToPreview={addToPreviewCtaBanner}
      />  

      <BuildInfoIcons 
        open={openInfoIcons}
        info={layoutInfo?.infoIcons}
        onClose={() => {
          addElementToPreview(layoutInfo.infoIcons, 'infoIcons')
          setOpenInfoIcons(false)
        }} 
        addToPreview={(info) => addElementToPreview(info, 'infoIcons', setOpenInfoIcons)}  
      />

      <BuildInfoPoints
        open={openInfoPoints}
        info={layoutInfo?.infoPoints} 
        onClose={() => {
          addElementToPreview(layoutInfo.infoPoints, 'infoPoints')
          setOpenInfoPoints(false)
        }} 
        addToPreview={(info) => addElementToPreview(info, 'infoPoints', setOpenInfoPoints)}  

      />
      <EditIntroduction
        open={openIntroduction}
        info={layoutInfo?.introduction} 
        onClose={() => {
          addElementToPreview(layoutInfo.introduction, 'introduction')
          setOpenIntroduction(false)
        }} 
        addToPreview={(info) => addElementToPreview(info, 'introduction', setOpenIntroduction)}  

      />
      <BuildSpotlightCourses 
        allCourses={courses} 
        open={openHighlightedCourses}
        info={layoutInfo?.highlightedCourses}
        onClose={() => {
          addElementToPreview(layoutInfo.highlightedCourses, 'highlightedCourses')
          setOpenHighlightedCourses(false)
        }}
        addToPreview={(info) => addElementToPreview(info, 'highlightedCourses', setOpenHighlightedCourses)}
      />

      <BuildSuggestedCourse 
        allCourses={courses} 
        open={openSuggestedCourse}
        info={layoutInfo?.suggestedCourse}
        onClose={() => {
          addElementToPreview(layoutInfo.suggestedCourse, 'suggestedCourse')
          setOpenSuggestedCourse(false)
        }}
        addToPreview={(info) => addElementToPreview(info, 'suggestedCourse', setOpenSuggestedCourse)}
      />



    </SubPage>
  );
};

export default EditShopFrontLayout;
