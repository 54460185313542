import React from 'react';
import { useSelector } from 'react-redux';
import { selectLayout } from '../homeSlice';
import {  selectCartItemsIds } from '../cartSlice';
import { selectWebshopCourses } from '../coursesSlice';

import ViewShopFrontLayout from './ViewShopFrontLayout';

const ViewShopFront = () => {

  const layoutStructure = useSelector(selectLayout);
  const courses = useSelector(selectWebshopCourses);
  const cartItems = useSelector(selectCartItemsIds);

  if (!layoutStructure) return null


  return (
    <ViewShopFrontLayout 
      layoutStructure={layoutStructure}
      courses={courses}
      cartItems={cartItems}
      {...layoutStructure}
    />
  );
};

export default ViewShopFront;
