import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { getUserThemeColor } from '../../../../ui/theme/colors';
import { Section, SpotlightCourseCard } from '../../../../ui'
import Carousel from '../../../../ui/Carousel';
import { selectStoreName, selectUserTheme } from '../../../../features/webshop/globalSlice';

const CarouselWrapper = styled.div`
  width: 100%;  
`;

const SpotlightCourses = ({ courses }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const storeName = useSelector(selectStoreName)
  const userTheme = useSelector(selectUserTheme);

  const goToCourse = useCallback((item) => {
    navigate(`/store/${storeName}/${item.slug}`);
  }, [navigate, storeName])

  if (!courses.length) return null

  return (
    <Section variant={getUserThemeColor(userTheme.color)} title={t('webshop.occasionCourses')}>
      <CarouselWrapper>
        <Carousel
          bgColor={userTheme.color}
          userThemeColor={getUserThemeColor(userTheme.color)}
          navigation={false}
          autoplay={{
            delay: 7500,
            disableOnInteraction: false,
          }}
        >
          {courses?.map((item) =>
            <SpotlightCourseCard
              themeColor={userTheme.color}
              course={item}
              key={item.id}
              goToCoursePage={() => goToCourse(item)}
            />
          )}
        </Carousel>
      </CarouselWrapper>
      
    </Section>
  );
};

export default SpotlightCourses;
