import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { enqueueSnackbar } from 'notistack'
import styled from 'styled-components'

import { CoverMedia, Section, Typo } from '../../../ui';
import { TOAST_ERROR } from '../../../utils/constants';
import { CONTENT_SMALL, media } from '../../../utils/media';
import { selectCourseCoverMedia, selectCtaBanner, selectLayout, selectLayoutId } from '../courseSlice';
import { selectCourses } from '../coursesSlice';
import { addItemToCart, selectCartItemsIds } from '../cartSlice';
import { selectStoreName, selectUserTheme } from '../globalSlice';

import ShowAccessTime from './components/ShowAccessTime';
import CourseInPackageInfo from './components/CourseInPackageInfo';
import CtaButton from './components/CtaButton';

import ListCourses from '../../../components/ListCourses';
import CTABanner from './components/CTABanner';



const Wrapper = styled.div` 
  max-width: ${CONTENT_SMALL}px;
  display:flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 2rem 0;

  ${media.lessThan('laptop')} {
    padding: 2rem 1.5rem;
  } 
`

const PackageDescription = styled(Wrapper)`
  gap:20px;
`

const ViewShopPackage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showCourseModal, setShowCourseModal] = useState(null)
  const course = useSelector(selectLayout)
  const courses = useSelector(selectCourses)
  const cartItems = useSelector(selectCartItemsIds);
  const storeName = useSelector(selectStoreName);
  const coverMedia = useSelector(selectCourseCoverMedia);
  const ctaBanner = useSelector(selectCtaBanner);
  const userTheme = useSelector(selectUserTheme);
  const courseId = useSelector(selectLayoutId);


  const addItem = useCallback((item) => {
    if (cartItems.find(el => el === item.id)) enqueueSnackbar(t('webshop.alreadyInCart'), { variant: TOAST_ERROR })
    else if (!!cartItems.length) {
      enqueueSnackbar(t('webshop.canOnlyBuyOne'), { variant: TOAST_ERROR })
    } else {
      dispatch(addItemToCart(item))
      navigate(`/store/${storeName}/checkout`);

    }
  }, [dispatch, cartItems, t, navigate, storeName]);

  const showPackageInfo = useCallback((course) => {
    setShowCourseModal(course)
  }, [])

  const closeModal = () => {
    setShowCourseModal(null)
  }

  const excludeActive = useCallback(() => courses.filter(item => item.id !== course.id && !!item.public), [courses, course])();

  const {
    introduction,
    // testimonies,
    packageItems
  } = course;

  if (course.status !== 'succeeded' || !course) return null

  return (
    <>
     <CoverMedia
        videoPreview={coverMedia?.video?.url}
        themeColor={userTheme.color}
        thumbnailImage={coverMedia?.image?.url}
        courseId={courseId}
      />
      <CTABanner
        themeColor={userTheme.color} 
        courseId={courseId} 
        buyButton={true} 
        addItemHandler={addItem}
        info={ctaBanner?.hasOwnProperty('title') ? ctaBanner : {title:'Begin your journey today!', description:'Unlock new skills and knowledge with a expert-led course. Enroll now!'}}
      />
      <PackageDescription >
        <Typo.PageHeader>{introduction?.title}</Typo.PageHeader>
        {introduction?.text && <Typo.Text>{introduction?.text}</Typo.Text>}
        {course.ucpPeriod && <ShowAccessTime ucpPeriod={course.ucpPeriod} />}
      </PackageDescription>
      {packageItems?.length > 1 &&
        <Wrapper>
          <Typo.ContentBigHeader $marginBottom='3rem'>{t('webshop.course.coursesIncluded')}</Typo.ContentBigHeader>
          <ListCourses showProgress={false} showButtons={false} items={packageItems} showPackageInfo={showPackageInfo} />
          <CtaButton addItemHandler={addItem} courseId={course.id} />
        </Wrapper>
      }
      {/* {!!Object.keys(highlightedInfo).length && <Section>
        <MainDescription info={highlightedInfo} hasBottomMargin infoIcons={infoIcons} courseId={course.id} />
        <CtaButton addItemHandler={addItem} courseId={course.id} />
      </Section>}
      <InfoPointsSection items={infoPoints} title={t('webshop.course.infoPointsTitle')} /> */}
      {/*{!!testimonies?.length && <Testimonies items={testimonies} />}*/}
      {!!excludeActive.length &&
        <Section title={t('webshop.course.relatedCourses')}>
          <ListCourses showProgress={false} showButtons={true} items={excludeActive} addItem={addItem} />
        </Section>
      }
      <CourseInPackageInfo onCloseModal={closeModal} course={showCourseModal} />
    </>
  );
};

export default ViewShopPackage;
