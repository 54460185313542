import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack'

import { CONTENT_MINI } from '../../../utils/media';
import { TOAST_ERROR } from '../../../utils/constants';
import { Typo, Section, Flex, CoverMedia } from '../../../ui'
import { useWebshopName } from '../../../hooks/readFromUrls';

import { addItemToCart } from '../cartSlice';
import { selectCourseById, selectHighlightedCoursesInfo } from '../coursesSlice';
import { selectUserTheme } from '../globalSlice';

import CTABanner from './components/CTABanner';
import SuggestedCourse from './components/SuggestedCourse';
import SpotlightCourses from './components/SpotlightCourses';


import InfoIconSection from '../components/InfoIconSection';
import InfoPointsSection from '../components/InfoPointsSection';
import ListCourses from '../../../components/ListCourses';

const coursesRef = React.createRef();
const aboutRef = React.createRef();
const contentRef = React.createRef();

const ViewShopFrontLayout = ({layoutStructure, courses, cartItems, 
  introduction, infoPoints, infoIcons, coverMedia, suggestedCourse, highlightedCourses, ctaBanner
}) => {
  const { t } = useTranslation();
  const { hash } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const webshop = useWebshopName();

  const suggestedCourseInfo = useSelector(state => 
    suggestedCourse && !Object.hasOwn(suggestedCourse, 'id') 
      ?  selectCourseById(state, suggestedCourse) : suggestedCourse)
  
  const spotlightedCoursesList = useSelector(state => selectHighlightedCoursesInfo(state, highlightedCourses));
  const userTheme = useSelector(selectUserTheme);


  const scrollTo = useCallback((refTarget) => {
    let headerOffset = 250;

    if (!refTarget.current) return null;
    let elementPosition = refTarget?.current.getBoundingClientRect().top;
    let offsetPosition = elementPosition - headerOffset;

    window.scrollBy({
      top: offsetPosition,
      behavior: "smooth"
    });
    // clean hash
    let noHashURL = window.location.href.replace(/#.*$/, '');
    window.history.replaceState('', document.title, noHashURL);
  }, []);

  useEffect(() => {
    if (hash) {
      if (hash === '#courses') scrollTo(coursesRef);
      else if (hash === '#content') scrollTo(contentRef);
      else if (hash === '#about') scrollTo(aboutRef);
    }
  }, [hash, scrollTo]);

  const addItem = useCallback((item) => {
    if (cartItems.find(el => el === item.id)) enqueueSnackbar(t('webshop.alreadyInCart'), { variant: TOAST_ERROR })
    else if (!!cartItems.length) {
      enqueueSnackbar(t('webshop.canOnlyBuyOne'), { variant: TOAST_ERROR })
    } else {
      dispatch(addItemToCart(item));
      navigate('checkout')
    }
  }, [dispatch, cartItems, t, navigate]);

  const goToCourses = useCallback(() => {
    navigate(`/store/${webshop}/#courses`);
  }, [navigate, webshop])

  if (!layoutStructure) return null

  const hasLayoutToShow = !!suggestedCourseInfo || !!spotlightedCoursesList.length

  console.log('suggestedCourse', suggestedCourseInfo, suggestedCourse)

  return (
    <>
      <CoverMedia
        videoPreview={coverMedia?.video?.url}
        thumbnailImage={coverMedia?.image?.url}
        themeColor={userTheme.color}
        />
      <CTABanner
        themeColor={userTheme.color} 
        goToAllCourses={goToCourses} 
        info={ctaBanner?.hasOwnProperty('title') ? ctaBanner : {title:'The best way to learn!', description:'Explore our courses led by industry experts. Whether you want to enhance your career, develop new skills, or pursue a passion, we have the right course for you. Start your learning journey today!'}} 
      />
      {!!infoIcons.length && <Section noMarginBottom={true} >
        <InfoIconSection items={infoIcons}/>
      </Section>}
      {!!Object.keys(introduction).length &&
        <Section size={CONTENT_MINI} >
          <Flex gap='24' direction='column'>
            <Typo.PageHeader>{introduction?.title}</Typo.PageHeader>
            <Typo.Text ref={aboutRef} >{introduction?.text}</Typo.Text>
          </Flex>
        </Section>
      }
      {!!spotlightedCoursesList.length &&
        <SpotlightCourses courses={spotlightedCoursesList} />
      }

      <InfoPointsSection items={infoPoints} title={t('webshop.home.infoPointsTitle')} refContent={contentRef} />

      {!!suggestedCourseInfo &&
        <SuggestedCourse
          suggestedCourse={suggestedCourseInfo}
          addToCart={addItem}
        />
      }
      {!!courses?.length && (courses?.length > 1 || !(hasLayoutToShow)) ?
        <Section title={t('webshop.home.coursesTitle')}>
          <ListCourses
            showProgress={false}
            showButtons={true}
            items={courses}
            addItem={addItem}
            refCourses={coursesRef} />
        </Section>
        : null
      }
    </>
  );
};

export default ViewShopFrontLayout;
