import React from 'react';
// import { useTranslation } from 'react-i18next';
import CheckboxButton from '../../../../ui/CheckboxButton';
import { List, ListItem } from '@mui/material';
import { IconAsButton, Typo } from '../../../../ui';


const EditPackageCourseSequence = ({ checked, onChange, courses, resortItems, sequence }) => {
  // const { t } = useTranslation();
  // const dispatch = useDispatch();

  const moveItem = (elementId, direction)=>{
    if (['up', 'down'].includes(direction) === false ) return false

    const order = [...sequence]
    const index = order.indexOf(elementId)
    order.splice(index, 1)

    if (direction === 'down') 
      order.splice(index+1, 0, elementId)
    else if (direction === 'up')
      order.splice(index-1, 0, elementId)
    
    resortItems(order)
  }

  return (
    <>
      <CheckboxButton 
        labelText={'open courses sequencially'}
        checked={checked} 
        handleChange={onChange} 
      /> 
      <Typo.Info $marginBottom='10px'>This allows to set order that courses will be available to student after they complete each course</Typo.Info>
      {sequence?.length > 1 && checked && <> 
        <Typo.Text>Set order of appearace of the courses</Typo.Text>
        <List>
          {sequence.map((id, index)=>{
            const courseToUse = courses.find((course)=> course.id === id)
            return (
              <ListItem key={courseToUse.id}>
                <Typo.Text>{courseToUse.name} - {courseToUse.id}</Typo.Text>
                <IconAsButton disabled={index === 0} tooltipLabel='move up' iconName='ArrowUp' clickHandle={()=> moveItem(courseToUse.id, 'up')}/>
                <IconAsButton disabled={index === courses.length - 1} tooltipLabel='move down' iconName='ArrowDown' clickHandle={() => moveItem(courseToUse.id, 'down')}/>
              </ListItem>
            ) 
          })}
        </List>  
      </>}
    
    </>
  );
};

export default EditPackageCourseSequence;
