import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';


import { selectUserTheme } from '../../globalSlice';

import { CoverMedia, Section, theme } from '../../../../ui';
import ShowTermsAndConditions from '../../../../components/ShowTermsAndConditions';
import ListCourses from '../../../../components/ListCourses';

import InfoIconSection from '../../components/InfoIconSection';

import ShopCourseToc from './ShopCourseToc';
import Teacher from './Teacher';
import CtaButton from './CtaButton';
import MainDescription from './MainDescription';
import CTABanner from './CTABanner';

const FullWidth = styled.div`
  display:flex;
  padding-top:10px;
  width:100%;
  justify-content:center;
`

const ShopCourseLayout = ({courses, addItemHandler, course, 
  coverMedia, ctaBanner, infoIcons, infoPoints, teacher, highlightedInfo, hideToc, activeModules
}) => {
  const { t } = useTranslation();

  const userTheme = useSelector(selectUserTheme);

  const excludeActive = useCallback(() => 
    courses?.filter(item => item.id !== course?.id && !!item.public), [courses, course])();

  if (course?.status !== 'succeeded' || !course) return null

  const {  introduction, courseToc, id, ucpPeriod } = course;

  const hasTeacher = (!activeModules || activeModules?.teacher) && !!teacher && !!Object.keys(teacher)?.length
  const showToc = !!activeModules ? activeModules?.toc : !hideToc && !!courseToc?.length
  const showInfoIcons = (!activeModules || activeModules?.infoPoints) && !!infoPoints?.length
  
  return (
    <>
      <CoverMedia
        videoPreview={coverMedia?.video?.url}
        themeColor={userTheme.color}
        thumbnailImage={coverMedia?.image}
        courseId={id}
        emptyCoverMedia={!!activeModules && !activeModules?.coverMedia}
      />
      <CTABanner
        themeColor={userTheme.color} 
        courseId={id} 
        buyButton={true} 
        addItemHandler={addItemHandler}
        info={ctaBanner?.hasOwnProperty('title') ? ctaBanner : {title:'Begin your journey today!', description:'Unlock new skills and knowledge with a expert-led course. Enroll now!'}}
      />
       <Section title={introduction?.title} noMarginBottom={!teacher || !Object.keys(teacher)?.length} > 
        <MainDescription 
          addItemHandler={addItemHandler} 
          infoIcons={!!activeModules && !activeModules?.infoIcons ? null : infoIcons} 
          info={!!activeModules && !activeModules?.highlightedInfo ? null : highlightedInfo} 
          ucpPeriod={ucpPeriod} 
          hasBottomMargin 
          courseId={id} 
        />
      </Section> 
      
      <Teacher teacherInfo={hasTeacher && teacher} />

      {hasTeacher && 
        <CtaButton addItemHandler={addItemHandler} courseId={id} compact={!teacher?.hasOwnProperty('about')} extraMargin={!showInfoIcons}/>}
      
      { showInfoIcons && <Section title={t('webshop.course.infoPointsTitle')} align='center' >
        <InfoIconSection items={infoPoints} />
      </Section>}
      
      {showToc && 
        <Section variant={theme.colors.NEUTRAL_LIGHT} title={t('webshop.course.courseContentTitle')}>
          <ShopCourseToc tocData={courseToc} />
        </Section>}
        
      <FullWidth width='100%' justify='center' >
        <ShowTermsAndConditions />
      </FullWidth>

      {(teacher || courseToc) && !!courses && !!excludeActive?.length && 
        <CtaButton addItemHandler={addItemHandler} courseId={id} />}
      
      {!!courses && !!excludeActive?.length &&
        <Section title={t('webshop.course.relatedCourses')}>
          <ListCourses showProgress={false} showButtons={true} items={excludeActive} addItem={addItemHandler} />
        </Section>
      } 
    </>
  );
};

export default ShopCourseLayout;
