import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { imageChecker } from '../../../../helpers/imageChecker';
import { CONTENT_SMALL, media } from '../../../../utils/media';
import { Typo, Figure, Button, Flex } from '../../../../ui';
import { getLighterThemeColor } from '../../../../ui/theme/colors';
import { selectStoreName, selectUserTheme } from '../../globalSlice';
import CtaButton from '../../ShopViewer/components/CtaButton';

const ImageBlock = styled.div`
  min-width: 231px;
  margin-right: 25px;

  ${media.lessThan('tablet')} {
    margin-right: 14px;
  }

  ${media.lessThan('phablet')} {
    margin-right: 0px;
    margin-bottom: 13px;
    width: 100%;
  }
`;

const CourseInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    h2 {
      padding-bottom: 10px;
      margin-bottom: 20px;
    }

    ${media.lessThan('phablet')} {
      gap: 10px;
    }
`;

const Wrapper = styled.div`
  background-color: ${({ $variant }) => $variant || 'transparent'};
`;

const Content = styled.div`
  max-width: 572px;
  z-index: 1;
  display:flex;
  flex-direction: column;
  margin: auto;
  padding: 0 0 3rem 0; 
  & >p{
    margin: 28px 0;
  }

  ${media.lessThan('tablet')} {
    padding: 0 1rem 3rem; 
  }
`;

const SuggestedWrapper = styled.div`
    display: flex;
    position: relative;
    background-color: ${(props) => props.theme.colors.NEUTRAL_0};
    padding: 48px 40px;
    
    box-shadow: ${(props) => props.theme.shadows.BOX_SHADOW_SMALL};

    ${media.lessThan('tablet')} {
      padding: 30px 30px;
    }

    ${media.lessThan('phablet')} {
      flex-direction: column;
      padding: 25px 25px;
    }
`;

const SuggestedCourse = ({ suggestedCourse, addToCart }) => {
  const { t } = useTranslation();
  const userTheme = useSelector(selectUserTheme)
  const navigate = useNavigate();
  const storeName = useSelector(selectStoreName)


  const { description, name, coverImage } = suggestedCourse

  const special = /(\?|\.|!)/; //? ! .
  const hasSpecialCharacter = description?.search(special) !== -1;

  const navigateToCourse = () => {
    const { slug, type, } = suggestedCourse
    navigate(`${`/store/${storeName}/${type === 2 ? 'package/' : ''}${slug}`}`);
  }

  return (
    <Wrapper size={CONTENT_SMALL} $variant={getLighterThemeColor(userTheme.color)} >
      <Content>
        <Typo.TextTitle>{t('webshop.home.popularCourse')}</Typo.TextTitle>
        <SuggestedWrapper>
          <ImageBlock>
            {!!coverImage?.length &&
              <Figure.Square>
                <img src={imageChecker(coverImage[0])} alt='courseImage' loading='lazy' />
              </Figure.Square>
            }
          </ImageBlock>
          <CourseInfo>
            <Flex direction={'column'} gap={10} align='flex-start'>
              <Typo.ContentBigHeader $textAlign={'left'}>
                {name}
              </Typo.ContentBigHeader>
              {!!description && <Typo.MediumText fontWeight={500}>
                {hasSpecialCharacter ? description.split(special)[0] + description.split(special)[1] : description}
              </Typo.MediumText>}
            </Flex>
            <Button $asText onClick={navigateToCourse}>{t('globals.moreInfo')}</Button>
            <CtaButton courseId={suggestedCourse.id} compact={true} addItemHandler={addToCart}/>
          </CourseInfo>
        </SuggestedWrapper>
      </Content>
    </Wrapper >
  );
};

export default SuggestedCourse;
