import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';

import { TOAST_ERROR } from '../../../utils/constants';
import { selectLayout } from '../courseSlice';
import { selectCourses } from '../coursesSlice';
import { addItemToCart, selectCartItemsIds } from '../cartSlice';
import { selectStoreName } from '../globalSlice';
import ShopCourseLayout from './components/ShopCourseLayout';

const ViewShopCourse = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const course = useSelector(selectLayout)
  const courses = useSelector(selectCourses)
  const cartItems = useSelector(selectCartItemsIds);
  const storeName = useSelector(selectStoreName);

  const addItem = useCallback((item) => {
    if (cartItems.find(el => el === item.id)) enqueueSnackbar(t('webshop.alreadyInCart'), { variant: TOAST_ERROR })
    else if (!!cartItems.length) {
      enqueueSnackbar(t('webshop.canOnlyBuyOne'), { variant: TOAST_ERROR })
    } else {
      dispatch(addItemToCart(item))
      navigate(`/store/${storeName}/checkout`);

    }
  }, [dispatch, cartItems, t, navigate, storeName]);

  if (course.status !== 'succeeded' || !course) return null

  return (
    <ShopCourseLayout 
      course={course} 
      {...course} 
      addItemHandler={addItem} 
      courses={courses}
    />
  );
};

export default ViewShopCourse;
